/* You can add global styles to this file, and also import other style files */
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

html,
body {
  margin: 0;
  font-family: "HM Group Ampersand";
  height: 100%;
  background: #ffffff;
}

@font-face {
  font-family: 'HM Group Ampersand';
  src: url('assets/font/HMGroupAmpersand-Regular.woff'),
    url('assets/font/HMGroupAmpersand-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.mat-body,
.mat-body-1,
.mat-typography {
  font-family: 'HM Group Ampersand' !important;
  letter-spacing: normal;
}

.mat-header-cell {
  font-size: 13px !important;
  font-weight: 600 !important;
  color: #03538B !important;
  background-color: #dfdfdfdf !important;
  font-family: 'HM Group Ampersand' !important;
}

.mat-header-cell .mat-sort-header-sorted {
  color: #03538B !important;

}

.mat-menu-item {
  font-family: 'HM Group Ampersand' !important;
  font-size: 14px;
  font-weight: 400;
}

.mat-h2,
.mat-title,
.mat-typography h2 {
  font-family: 'HM Group Ampersand';
  letter-spacing: normal;
  padding: 0.4rem;
  font-size: 16px;
  line-height: 26.15px;
  margin: 0px 0px 0px 30px;
  color: #03538B !important;
  font-weight: bold;
}
::ng-deep .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color:#03538B !important;
}
.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: 'HM Group Ampersand' !important;
  letter-spacing: normal;
  width: 100%;
}

.mat-option-text {
  display: inline-block;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

.mat-menu-content:not(:empty) {
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100% !important;
}

.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
  font-family: 'HM Group Ampersand';
  font-size: 14px;
  font-weight: 500;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: 'HM Group Ampersand';
  font-size: 12px;
}

.mat-checkbox {
  font-family: 'HM Group Ampersand';
}

.mat-select {
  font-family: 'HM Group Ampersand';
}

.mat-form-field-appearance-outline {
  font-family: 'HM Group Ampersand';
}

.mat-option-text {
  font-family: 'HM Group Ampersand';
}

.mat-table {
  overflow: auto;
  max-height: 700px;
}



::ng-deep tr.mat-header-row {
  height: 43px !important;
}

::ng-deep tr.mat-footer-row {
  height: 43px !important;
}

::ng-deep tr.mat-row,
tr.mat-footer-row {
  height: 22px !important;
}

::ng-deep .mat-paginator {

  display: block;
  height: 38px !important;
}

::ng-deep .mat-cell,
.mat-footer-cell {
  font-size: 12px;
}

md-pagination-wrapper {
  width: auto;
}

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    background-color: #ebecec !important;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgb(163, 162, 162); 
    border-radius: 5px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(185, 184, 184); 
    border-radius: 25px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(173, 173, 173); 
  }

::ng-deep .mat-select-arrow {
  color: #000;
}


::ng-deep .mat-paginator-container {
  height: 47px !important;
}

::ng-deep .mat-paginator-container {
  min-height: 0px;
}





.mat-raised-button.ml-1:not([disabled]) {

  color: #FFFFFF;
  background-color: #00558d !important;
  height: 37px;
  /* margin: 6px 0px 36px 26px; */
}

.mat-option.mat-active {
  background: rgb(192 192 192);
  color: rgba(0, 0, 0, .87);
}

.mat-option:hover:not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled) {
  background: rgb(192 192 192) !important;
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background-color: #00558d;
}

::ng-deep .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background-color: #00558d;
  background: #00558d !important;
}



.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background.mat-checkbox-checked.mat-primary.mat-checkbox-background {
  background-color: #00558d;
}

::ng-deep .mat-checkbox-background {
  background: #00558d !important
}

.mat-option {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  line-height: 31px;
  height: 48px;
  padding: 0 14px;
  text-align: left;
  text-decoration: none;
  max-width: 100%;
  position: relative;
  cursor: pointer;
  outline: none;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  box-sizing: border-box;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  font-size: 100%;
}


::ng-deep .mat-checkbox-custom {
  background-color: #00558d !important;
}

::ng-deep mat-option.mat-focus-indicator.ng-tns-c98-16.ng-star-inserted:last-child:before {
  content: 'ALL';
  float: left;
  text-transform: none;
  top: 4px;
  position: relative;
}

::ng-deep mat-option.mat-focus-indicator.ng-tns-c98-16.ng-star-inserted:last-child .mat-option-text {
  display: none;
  position: relative;
}

/* Common classes */
mat-row,
mat-header-row,
mat-footer-row,
th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  border-bottom-color: rgb(249 246 246) !important;
}

.data-table {
  width: 97%;
  margin-left: 2%;
  margin-top: 2%;
  margin-bottom: 0%;
  /* overflow-x: scroll !important; */
}

.wrap {
  width: 100%;
  overflow: auo;
}

.org-container {
  width: 97%;
  margin: 1% 1% 2% 1%;
  /* border: #e2e2e2 0.02em solid;
  border-radius: 5px; */
}

.org-table {
  border-radius: 5px;
  border: #e2e2e2 0.02em solid;
  width: 96%;
  margin: 0% 0% 0% 0.6%;
}
.org-table-set {
  border-radius: 5px;
  border: #e2e2e2 0.02em solid;
  width: 96%;
  margin: 0% 0% 0% 0.5%;
}
.form-note {
  color: #ff0202;
  font-size: 12px;
  font-weight: 400;
}

.org-openmodel {
  color: #444 !important;
  cursor: pointer;
  text-decoration: underline;
}

::ng-deep .dispaynone {
  display: none !important;
}

::ng-deep .dispayblock {
  display: block !important;
}

.my-custom-tooltip {
  max-width: unset !important;
}

.clearfix {
  overflow: auto;
  content: "";
  display: table;
  clear: both;
}

.fright {
  min-height: 98vh;
}

#main-wrap {
  overflow: hidden;
}
#main-ag {
  position: relative;
  margin-top: -44px;
}

#drawerleft~#drawerright #sidebar {
  float: left;
  width: 30% !important;
  height: 38px;
}

#drawerleft~#drawerright #tblPaginator {
  float: right;
  width: 70% !important;
}

#drawerleft.mat-drawer-opened~#drawerright #sidebar {
  float: left;
  width: 34% !important;
  height: 38px;
}

#drawerleft.mat-drawer-opened~#drawerright #tblPaginator {
  float: right;
  width: 66% !important;
}

.activeindicator {
  color: #fff;
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: black;
}

.firstdraftindicator {
  color: #D7ECFF;
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: black;
}

.draftindicator {
  color: #FFE7CE;
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: black;
}

.inactiveindicator {
  color: #AAAAAA;
  -webkit-text-stroke-width: 0.5px;
  -webkit-text-stroke-color: black;
}


.displaycls {
  display: inline;
}

.hiddedencls {
  display: none;
}

.width-5 {
  width: 5%;
}

.width-10 {
  width: 10%;
}

.width-25 {
  width: 25%;
}

.width-15 {
  width: 15%;
}

.ml-1 {
  margin-left: 1%;
}

.ml-2 {
  margin-left: 2%;
}

.ml-10 {
  margin-left: 10%;
}
.depsearch{

  width:90%;
  margin-left: 5%;
}
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}

.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}

.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* mat-confirm-dialog (customised dialog) */
.confirm-dialog-container .mat-dialog-container {
  border-radius: .25em .25em .4em .4em;
  padding: 0px;
}

.confirm-dialog-container .content-container {
  margin: 5px 5px 15px 5px; 
  color: #8f9cb5;
  display: flex;
}

.confirm-dialog-container #close-icon {
  margin-left: auto;
  order: 2;

}

.confirm-dialog-container #close-icon:hover {
  cursor: pointer;
}

.confirm-dialog-container #no-button {
  height: 50px;
  width: 50%;
  background-color: #8a5d5a;
  color: white;
  border-radius: 0px;
}

.confirm-dialog-container #yes-button {
  height: 50px;
  width: 50%;
  background-color: #00395f;
  color: white;
  border-radius: 0px;
}

.confirm-dialog-container span.content-span {
  padding: 35px 16px;
  text-align: center;
  font-size: 18px;
}

/* Active, Draft, Inactive BGD Style */
.DraftBg {
  background-color: #FFE7CE;
}

.FiscalDraftBg {
  background-color: #d7bd5e;
}

.ActiveBg {
  background-color: #fff;
}

.InactiveBg {
  background-color: #AAAAAA;
}

.FirstTimeDraftBg {
  background-color: #D7ECFF;
}

.org-edit {
  color: #444 !important;
  cursor: pointer;
  text-decoration: underline;
}
.indicator button {
  width:25%;
  color: #444;
  font-size: 11px;
 }
 #tblPaginator {
    float: left !important;
    width: 100%;
    color: #555555;
  }
#Paginator-ag {
  float: left !important;
  width: 100%;
  color: #555555;
}
  .aligncenter{
    text-align: center;
  }
/* Media query starts here */
@media all and (min-width: 320px) and (max-width: 768px) {
  .org-container {
    width: 96%;
    margin: 12px !important;
    padding: 12px !important;
    border: #e2e2e2 0.02em solid;
    border-radius: 5px;
  }

  .mat-form-field-wrapper {
    width: 75% !important;
    padding: 12px !important;
  }

  #sidebar {
    display: none !important;
    background-color: #dfdfdfdf !important;
  }

  #tblPaginator {
    float: left !important;
    width: 100%;
    color: #555555;
  }
  #tblPaginator-ag {
    float: left !important;
    width: 100%;
    color: #555555;
  }

  #displaygrid {
    display: block !important;
  }

  .width-15 {
    width: 30% !important;
    height: 46px;
  }

  #accToggle button {
    display: none !important;
  }

  #drawerright {
    width: 100%;

  }

  .width-20 {
    width: 50% !important;
    margin-top: 2%;
    margin-left: 1%;
    height: 56px;
  }

  #tblPaginator {
    float: left !important;
    width: 100%;
  }

  #Paginator-ag {
    float: left !important;
    width: 100%;
  }
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }

  ::ng-deep .mat-icon-button {
    padding: 0;
    min-width: 0;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    line-height: 40px;
    border-radius: 50%;
    font-family: 'HM Group Ampersand';
    font-size: 13px !important;
    font-weight: 500 !important;
  }

  #drawerleft.mat-drawer-opened~#drawerright {
    width: 100%;
  }

  #drawerright {
    width: 100% !important;
  }

  #drawerleft {
    width: 100% !important;
  }

  .tblleft {
    float: left;
    padding: 3% !important;
    width: 100% !important;
    height: 400px;
    overflow-x: scroll !important;
  }

  .frmright {
    float: left;
    padding: 3% !important;
    width: 100% !important;
    height: auto !important;
  }

}

@media all and (min-width: 769px) and (max-width: 959px) {
  #displaygrid {
    display: none !important;
  }

  #sidebar {
    float: left;
    width: 30%;
    height: 38px;
    display: block !important;
    background-color: #dfdfdfdf !important;
  }
  #sidebar-ag {
    float: left;
    width: 30%;
    height: 38px;
    display: block !important;
    background-color: #dfdfdfdf !important;
  }
  #drawerright {
    width: 100%;
    top: 0;
    position: absolute !important;
  }

  .tblleft {
    float: left;
    padding: 3% !important;
    width: 100% !important;
    height: 400px;
    overflow-x: scroll !important;
  }

  .frmright {
    float: left;
    padding: 3% !important;
    width: 100% !important;
    height: auto !important;
  }
}

@media all and (min-width: 960px) and (max-width:1024px) {

  .width-11 {
    width: 15% !important;
  }

  .add-div {
    margin-left: 2% !important;
    margin-top: 4% !important;
  }


  #displaygrid {
    display: none !important;
  }

  #sidebar {
    display: none !important;
    background-color: #dfdfdfdf !important;
  }
  #sidebar-ag {
    display: none !important;
    background-color: #dfdfdfdf !important;
  }

  .tblleft {
    float: left;
    padding: 3% !important;
    width: 100% !important;
    height: 400px;
    overflow-x: scroll !important;
  }

  .frmright {
    float: left;
    padding: 3% !important;
    width: 100% !important;
    height: auto !important;
  }

  #tblPaginator {
    float: left !important;
    width: 100%;
  }
  #Paginator-ag {
    float: left !important;
    width: 100%;
  }

  #navhorz {
    display: block !important;
  }

  #drawerright {
    width: 100%;
    top: 0;
    position: absolute !important;
  }
}

@media all and (min-width: 1024px) and (max-width:2560px) {
  .wrap {
    width: 100%;
    overflow: auo;
  }
  .fixed-content {
    position:fixed;
    overflow-y:auto;
    height:500px;
    width:26.8%;
    background-color: #f5f5f5;
  }
  .tblleft {
    float: left;
    margin-left: .6%;
    width: 70%;
    height: 400px;
  }

  .frmright {
    float: left;
    margin-left: .6%;
    width: 26.8%;
    height: auto !important;
  }

  .customform {
    margin: 0% 0% 0% 2%;
    width: 300px;
  }

  .statusclass{
    width: 136px !important; margin-left:2% !important;
  }

  .ml-2{margin-left: 2% !important;}

  .custombutton {
    margin: 2% 3% 0% 3%;
  }

  .mat-icon.mat-primary {
    color: #00558d;
  }

  .mat-sort-header-content {
    text-align: left !important;
    display: flex;
    align-items: center;
}

 
  .entry-div {
    margin-top: 2% !important;
  }

  ::ng-deep #custom-col250 {
    width: 250px !important;
  }

  ::ng-deep #custom-col110 {
    width: 110px !important;
  }

  ::ng-deep #custom-col150 {
    width: 150px !important;
  }

  #custom-col80 {
    width: 80px !important;
  }

  ::ng-deep.mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: #79747E !important;
  }

  ::ng-deep.mat-select-panel-wrap [ng-reflect-vale="active"] {
    flex-basis: 100%;
    margin-top: 58% !important;
  }

  #sidebar {
    float: left;
    width: 36%;
    height: 38px;
    background-color: #dfdfdfdf !important;
  }
  #sidebar-ag {
      float: left;
      width: 58%;
      height: 38px;
 }
  #content-wrap {
    float: right;
    width: 70%;
  }

  #tblPaginator {
    float: right;
    width: 64%;
  }

  #Paginator-ag {
    width: 35%;
    margin-top: 10px;
  }
  .mat-paginator {
    color: #444444 !important;
    background-color: #dfdfdfdf !important;
    width: 62.2%;
  }

  #displaygrid {
    display: none !important;
  }

  .add-div {
    margin-left: 1% !important;
    margin-top: 4% !important;
  }

  #navhorz {
    display: block !important;
  }

  #drawerright {
    width: 100%;
    top: 0;
    position: absolute !important;
  }

  .add-div {
    margin-left: 2%;
    margin-bottom: 0%;
    margin-top: 4% !important;
  }

  /* style for create and update form heading align */
  .align{
    margin-left: 15px !important;
  }

}
.info2 {
  margin-bottom: 80px !important;
  }

  .panelinner, .mat-select-panel {
    max-height: 400px !important;
}
.deptsection{
  display: flex; justify-content: center;
}
.spinner{
  float:right !important;
}
.ag-paging-panel {
  border-top: 1px solid;
  border-top-color: var(--ag-border-color);
  color: var(--ag-secondary-foreground-color);
  height: var(--ag-header-height);
  background-color: #dfdfdfdf !important;
}
.ag-header-viewport {
  position: relative;
  height: 100%;
  min-width: 0px;
  overflow: hidden;
  flex: 1 1 auto;
  background-color: #dfdfdfdf !important;
}

.mat-select-panel.custom-select {
  left: -150px;
  max-height: 512px !important;
  bottom: -212px;
  min-width: 145% !important;
  position: absolute;
  overflow: hidden;
} 

.mat-select-panel.product-custom-select {
  left: -110px;
  bottom: -170px;
  min-width: 145% !important;
  position: absolute;
}

/* .mat-form-field-wrapper.depsearch {
  top: 8px !important;
  position: relative !important;
} */